import React, { useEffect, useState } from "react"
import styles from "./nav.module.scss"

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: 0,
            visible: true
        };
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true
        };
        window.addEventListener("scroll", this.handleScroll);
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos || currentScrollPos < 100;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    render() {
        return( <nav className={styles.nav + " " + this.props.color +" "+(this.state.visible ? "" : styles.navbarHidden)}>
              {this.props.children}
        </nav>
        );
    }
}