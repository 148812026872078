import React,{useEffect,useState} from "react"
import styles from "./sidenav.module.scss"
import CSSTransition from "react-transition-group/CSSTransition"
  function AnimatedVisibility({ visible, children }) {
  const [noDisplay, setNoDisplay] = useState(!visible);
  useEffect(() => {
    if (!visible) setTimeout(() => setNoDisplay(true), 400);
    else setNoDisplay(false);
  }, [visible]);

  const style = noDisplay ? { display: "none" } : null;
  return (
    <CSSTransition
      timeout={500}
      classNames="sidenavContent"
      in={visible}
    >
      <div style={style} className={styles.sidenavContent}>
        {children}
      </div>
    </CSSTransition>
  );
}
export default AnimatedVisibility