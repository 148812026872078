import React, { useEffect, useState } from "react"
import "../styles/root.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import NavWrapper from "../components/nav/nav-wrapper"
import ImageFilter from "../components/photography/ImageFilter"
import stylesnav from "../components/nav/nav.module.scss"
import Wrapper from "../components/wrapper"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"

function Contact() {
  return (
    <>
      <SEO title="Contact" keywords={[`Florian Parfuss`]}  />
      <NavWrapper title={"Contact"}>
      </NavWrapper>
      <Wrapper>
        <section className="kontakt">
          <div className="container">
            <div className="hello-container">
              <div className="display-1 text-center hello">Hallo*</div>
              <h3 className="text-center">*Wie ich dir helfen? :)</h3>
            </div>
            <div className="mt-5">
              <h4 className="text-center mail mt-5">Erreichbar unter:</h4>
              <h3 className="text-center mail">florian.parfuss@hotmail.de</h3>
            </div>
          </div>
        </section>
      </Wrapper>
      <Footer/>
    </>
  )
}
export default Contact