import React,{useEffect,useState} from "react"
import "../styles/root.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import NavWrapper from "../components/nav/nav-wrapper"
import Wrapper from "../components/wrapper"
import stylesnav from "../components/nav/nav.module.scss"
import ImageWall from "../components/photography/ImageWall"
import ImageFilter from "../components/photography/ImageFilter"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"

function Photography() {
  const [categories,SetCategories] = useState([])
  function SetterCategories(category) {
    var temp = [...categories]
    if(temp.includes(category)){
      SetCategories(categories.filter(item => item !== category))
    }
    else{
      temp.push(category)
      SetCategories(temp)
    }
  }

  function isincategory(category){
    if(categories.includes(category)){
      return true;
    }
    return false;
  }

  return(
    <>
      <SEO title="Photography" keywords={[`Florian Parfuss`]}  />
      <NavWrapper title={"Photography"}>
        <ImageFilter>
          <div className={stylesnav.pill + " "+ (isincategory("Portrait") ? stylesnav.active : "")} category={"Portrait"} onClick={() =>SetterCategories("Portrait")}>Portrait</div>
          <div className={stylesnav.pill + " "+ (isincategory("Nature") ? stylesnav.active : "")} category={"Nature"} onClick={() => SetterCategories("Nature")}>Nature</div>
          <div className={stylesnav.pill + " "+ (isincategory("Architecture") ? stylesnav.active : "")}  category={"Architecture"} onClick={() => SetterCategories("Architecture")}>Architecture</div>
          <div className={stylesnav.pill + " "+ (isincategory("Street") ? stylesnav.active : "")}  category={"Street"} onClick={() => SetterCategories("Street")}>Street</div>
        </ImageFilter>
      </NavWrapper>
      <ImageWall category={categories}/>
      <Footer/>
    </>
  )
}
export default Photography