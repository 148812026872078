import React, { useEffect, useState } from "react"
import "../styles/root.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import NavWrapper from "../components/nav/nav-wrapper"
import ImageFilter from "../components/photography/ImageFilter"
import stylesnav from "../components/nav/nav.module.scss"
import Wrapper from "../components/wrapper"
import {
  Container,
  ListGroup,
  Button, Col,
} from "react-bootstrap"
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import {useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import styles from "../styles/work.module.scss"
import { graphql } from "gatsby"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"

function Work() {
  let data = useStaticQuery(graphql`
      {
        allMysqlProjects{
          edges{
            node{
              p_id
              p_name
              p_category
              mysqlImage {
                 childImageSharp {
                   fluid {
                   ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  `)
  const [items] = useState(prepareItems(data.allMysqlProjects.edges.sort(dynamicSort('p_id'))));
  const [categories,SetCategories] = useState([])

  return(
      <>
        <SEO title="Work" keywords={[`Florian Parfuss`]}  />
        <NavWrapper title={"Work"}>
          <ImageFilter>
            <div className={stylesnav.pill + " "+ (isincategory("Print") ? stylesnav.active : "")} category={"Print"} onClick={() =>SetterCategories("Print")}>Print</div>
            <div className={stylesnav.pill + " "+ (isincategory("Web") ? stylesnav.active : "")} category={"Web"} onClick={() => SetterCategories("Web")}>Web</div>
            <div className={stylesnav.pill + " "+ (isincategory("Development") ? stylesnav.active : "")}  category={"Development"} onClick={() => SetterCategories("Development")}>Development</div>
            <div className={stylesnav.pill + " "+ (isincategory("Photography") ? stylesnav.active : "")}  category={"Photography"} onClick={() => SetterCategories("Photography")}>Photography</div>
          </ImageFilter>
        </NavWrapper>
        <Wrapper>
          <Container style={{ marginTop: '2rem' }}>
            <ListGroup style={{ marginBottom: '1rem' }}>
              <TransitionGroup className={styles.grid}>
                {filterWork().map(({ node }) => (
                  <CSSTransition
                    key={node.p_id}
                    timeout={500}
                    classNames="item"
                  >
                    <div className={styles.gridItem}>
                      <Img fadeIn="true" fluid={node.mysqlImage.childImageSharp.fluid}
                           className={styles.imageWrapper} />
                      <div className={styles.overlayBg}/>
                      <div className={styles.overlay}>
                        <div className={styles.content}><h2>{node.p_name}</h2><p>{node.p_category.join(", ")}</p></div>
                      </div>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </ListGroup>
          </Container>
        </Wrapper>
        <Footer/>
      </>
  )
  function dynamicSort(property) {
    return function(a, b) {
      return (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    }
  }
  function prepareItems(rows) {
    if(!Array.isArray(rows[0].node.p_category)){
      for (let x = 0; x < rows.length; x++) {
        rows[x].node.p_category = rows[x].node.p_category.split(",")
      }
    }
    return rows;
  }
  function SetterCategories(category) {
    var temp = [...categories]
    if(temp.includes(category)){
      SetCategories(categories.filter(item => item !== category))
    }
    else{
      temp.push(category)
      SetCategories(temp)
    }
    filterWork();
  }
  function filterWork() {
    if (categories.length > 0) {
      var temp = []
      for (let x = 0; x < items.length; x++) {
        var add = true;
        for (let i = 0; i < categories.length; i++) {
          if (!items[x].node.p_category.includes(categories[i])) {
            add = false;
            break;
          }
        }
        if(add === true) {
          temp.push(items[x]);
        }
      }
      return temp
    }
    return items
  }

  function isincategory(category){
    if(categories.includes(category)){
      return true;
    }
    return false;
  }
}
export default Work