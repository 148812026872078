import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./ImageWall.module.scss"
import { Col } from "react-bootstrap"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { func } from "prop-types"
import Row from "react-bootstrap/Row"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons"

const ImageWall = (props) => {
  const data = useStaticQuery(graphql`
    
      {
        allMysqlPerson {
          edges {
            node {
              p_id
              p_name
              p_category
              p_location
              p_date
              p_color
              mysqlImage {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              pictures {
                p_id
                p_path
                p_size
                p_rating
                p_person
                mysqlImage {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
  `)


  function hasBig(rows, index) {
    for (let x = index; x < (index + 3); x++) {
      if (rows[x] != null && rows[x].node.p_size === 1) {
        return true
      }
    }
    return false
  }

  function getCols(data, category) {

    data = data.sort((a, b) => (a.node.p_id < b.node.p_id) ? -1 : 1)
    if (!Array.isArray(data[0].node.p_category)) {
      for (let x = 0; x < data.length; x++) {
        data[x].node.p_category = data[x].node.p_category.split(",")
      }
    }
    if (props.category.length > 0) {
      var temp = []
      for (let x = 0; x < data.length; x++) {
        var add = true
        for (let i = 0; i < props.category.length; i++) {
          if (!data[x].node.p_category.includes(props.category[i])) {
            add = false
            break
          }
        }
        if (add === true) {
          temp.push(data[x])
        }
      }
      data = temp
    }
    console.log(data)
    let rowcount = 0
    let tempwall = []
    for (let index = 0; index < data.length; index++) {
        tempwall[index] = <CSSTransition
          key={data[index].node.p_id}
          timeout={500}
          classNames="item"
        ><Row className={"w-100 "+styles.row+" "+(data[index].node.p_color === "dark" ? styles.dark : styles.light )}>
          <Col xs={12} className={"d-flex align-items-center justify-content-center"}>
            <div className={styles.HeroContainer}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.HeroImage }/>
              <div className={styles.HeroImageHeadline}>{data[index].node.p_name}</div>
              <div className={styles.HeroImageHeadlineInvert}>{data[index].node.p_name}</div>
              <div className={styles.HeroImageText}><strong>Location: </strong>{data[index].node.p_location}<br/><strong>Date:</strong> 13.04.2020</div>
            </div>
          </Col>
          {data[index].node.pictures.map((node) => <Col xs={6} sm={6} md={4} lg={4} xl={2} className={"d-flex align-items-center justify-content-center p-3"}>
            <Img fadeIn="true" fluid={node.mysqlImage.childImageSharp.fluid}
                 className={styles.imageWrapper}/>
          </Col>)}
          {data[index].node.p_color === "dark" ? <div className={styles.darkEnding}/> : null}
        </Row></CSSTransition>
    }
    return [tempwall]
  }

  return (
    <TransitionGroup className={"row w-100 " + styles.wrapper}>
      {getCols(data.allMysqlPerson.edges).map((node) => (node))}
    </TransitionGroup>
  )
}
export default ImageWall
/*
if (!Array.isArray(data[0].node.p_category)) {
      data = data.sort((a, b) => (a.node.p_rating < b.node.p_rating) ? -1 : 1)
      for (let x = 0; x < data.length; x++) {
        data[x].node.p_category = data[x].node.p_category.split(",")
      }
    }
    if (props.category.length > 0) {
      var temp = []
      for (let x = 0; x < data.length; x++) {
        var add = true
        for (let i = 0; i < props.category.length; i++) {
          if (!data[x].node.p_category.includes(props.category[i])) {
            add = false
            break
          }
        }
        if (add === true) {
          temp.push(data[x])
        }
      }
      data = temp
    }
    console.log(data)
    let rowcount = 0
    let tempwall = []
    for (var index = 0; index < data.length; index++) {
      tempwall[rowcount] = []
      if (data[index].node.p_size === 2) {
        tempwall[rowcount] = <CSSTransition
          key={data[index].node.p_id}
          timeout={500}
          classNames="item"
        ><Col xs={12} lg={4} xl={6} className={"d-flex align-items-center justify-content-center"}>
          <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
               className={styles.imageWrapper + " " + styles.xlarge}/>
        </Col></CSSTransition>
      } else if (containsBig(data, index)) {
        if (data[index + 1].node.p_size === 1) {
          if (Math.round(Math.random()) > 0) {
            var image = data[index + 2].node.mysqlImage.childImageSharp.fluid
            tempwall[rowcount][0] = <CSSTransition
              key={data[index].node.p_id}
              timeout={500}
              classNames="item"
            ><Col xs={4} lg={4} xl={6}
                  className={"d-flex align-items-center justify-content-center flex-column"}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.imageWrapper}/>
              <Img fadeIn="true" fluid={image}
                   className={styles.imageWrapper}/>
            </Col></CSSTransition>
            tempwall[rowcount][1] =
              <CSSTransition
                key={data[index + 1].node.p_id}
                timeout={500}
                classNames="item"
              ><Col xs={8} lg={4} xl={6} className={"d-flex align-items-center justify-content-center"}>
                <Img fadeIn="true" fluid={data[index + 1].node.mysqlImage.childImageSharp.fluid}
                     className={styles.imageWrapper + " " + styles.large}/>
              </Col></CSSTransition>
            index += 2
          } else {
            var image = data[index + 2].node.mysqlImage.childImageSharp.fluid
            tempwall[rowcount][0] =
              <CSSTransition
                key={data[index].node.p_id}
                timeout={500}
                classNames="item"
              >
                <Col xs={8} lg={4} xl={6} className={"d-flex align-items-center justify-content-center"}>
                  <Img fadeIn="true" fluid={data[index + 1].node.mysqlImage.childImageSharp.fluid}
                       className={styles.imageWrapper + " " + styles.large}/>
                </Col></CSSTransition>
            tempwall[rowcount][1] = <CSSTransition
              key={data[index + 1].node.p_id}
              timeout={500}
              classNames="item"
            ><Col xs={4} lg={4} xl={6}
                  className={"d-flex align-items-center justify-content-center flex-column"}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.imageWrapper}/>
              <Img fadeIn="true" fluid={image}
                   className={styles.imageWrapper}/>
            </Col></CSSTransition>
            index += 2
          }
        } else {
          var image = data[index + 1].node.mysqlImage.childImageSharp.fluid
          tempwall[rowcount][0] = <CSSTransition
            key={data[index].node.p_id}
            timeout={500}
            classNames="item"
          >
            <Col xs={4} lg={4} xl={6} className={"d-flex align-items-center justify-content-center flex-column"}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.imageWrapper}/>
              <Img fadeIn="true" fluid={image}
                   className={styles.imageWrapper}/>
            </Col></CSSTransition>
          tempwall[rowcount][1] =
            <CSSTransition
              key={data[index + 2].node.p_id}
              timeout={500}
              classNames="item"
            >
              <Col xs={8} lg={4} xl={6} className={"d-flex align-items-center justify-content-center"}>
                <Img fadeIn="true" fluid={data[index + 2].node.mysqlImage.childImageSharp.fluid}
                     className={styles.imageWrapper + " " + styles.large}/>
              </Col>
            </CSSTransition>
          index += 2
        }
      } else {
        tempwall[rowcount] = []
        if (data[index] != null) {
          tempwall[rowcount][0] = (
            <CSSTransition
              key={data[index].node.p_id}
              timeout={500}
              classNames="item"
            >
              <Col xs={4} lg={4} xl={6} className={"d-flex align-items-center justify-content-center flex-column"}>
                <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                     className={styles.imageWrapper}/>
              </Col></CSSTransition>)
          index++
        }
        if (data[index] != null) {
          tempwall[rowcount][1] = (
            <CSSTransition
              key={data[index].node.p_id}
              timeout={500}
              classNames="item"
            ><Col xs={4} lg={4} xl={6} className={"d-flex align-items-center justify-content-center flex-column"}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.imageWrapper}/>
            </Col></CSSTransition>)
          index++
        }
        if (data[index] != null) {
          tempwall[rowcount][2] = (
            <CSSTransition
              key={data[index].node.p_id}
              timeout={500}
              classNames="item"
            ><Col xs={4} lg={4} xl={6} className={"d-flex align-items-center justify-content-center flex-column"}>
              <Img fadeIn="true" fluid={data[index].node.mysqlImage.childImageSharp.fluid}
                   className={styles.imageWrapper}/>
            </Col></CSSTransition>)
        }
      }
      rowcount++
    }
    return [tempwall]
 */


/*
          <Col xs={6} sm={6} md={4} lg={4} xl={2} className={"d-flex align-items-center justify-content-center flex-column p-3"}>
            <div className={"display-4"}>Mehr<br/>Fotos</div>
            <div className={"p-5"}>
              <FontAwesomeIcon icon={faArrowRight} size={"2x"}/>
            </div>
          </Col>
 */