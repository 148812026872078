import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState } from "react"
import styles from "./footer.module.scss";
import { Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
function Footer(props) {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.content}>
          <div className="row">
            <ul className="nav flex-column align-items-start justify-content-start">
              <li className="nav-item display-4">
                <a className={styles.navLink} href={"/"}>Home</a>
              </li>
              <li className="nav-item display-4">
                <a className={styles.navLink} href={"/skills"}>Skills</a>
              </li>
              <li className="nav-item display-4">
                <a className={styles.navLink} href={"/photography"}>Photo</a>
              </li>
              <li className="nav-item display-4">
                <a className={styles.navLink} href={"/work"}>Work</a>
              </li>
              <li className="nav-item display-4">
                <a className={styles.navLink} href={"/contact"}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.social_bar}>
          <div className="row align-items-center">
            <h3 className={{marginBottom: 0}}>Bleib in Kontakt.</h3>
            <div className={styles.social_wrap}>
                <a href="https://www.facebook.com/florian.ela.1" target="_blank"><FontAwesomeIcon icon={faInstagram} size={"lg"}/></a>
                <a href="https://www.instagram.com/florianparfuss/" target="_blank"><FontAwesomeIcon icon={faFacebook} size={"lg"}/></a>
                <a href="https://at.linkedin.com/in/florian-parfuss-733221182" target="_blank"><FontAwesomeIcon icon={faLinkedin} size={"lg"}/></a>
            </div>
          </div>
        </div>
        <div className={styles.legal}>
          <div className="row">
            <h6>Copyright &copy;
              <script>document.write(new Date().getFullYear());</script>
              &nbsp;Florian Parfuss
            </h6>
            <h6><a href="impressum.html">Impressum</a></h6>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer