import Nav from "./nav"
import "bootstrap/dist/css/bootstrap.min.css"
import sidenav from "./sidenav.module.scss"
import React, { useState } from "react"
import stylesnav from "./nav.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH, faTimes } from "@fortawesome/free-solid-svg-icons"
import AnimatedVisibility from "./AnimatedContainer"
import Photography from "../../pages/photography"
import Work from "../../pages/work"
import Contact from "../../pages/contact"

function NavWrapper(props) {
  const [color, SetColor] = useState(stylesnav.black)
  const [backcolor, SetBackColor] = useState(color)
  const [navIsOpen, setNavOpen] = useState(false)

  function toggleSidebar() {
    if (navIsOpen) {
      SetBackColor(color)
      SetColor(backcolor)
      document.body.classList.remove(sidenav.sidenavOpen)
    } else {
      SetBackColor(color)
      SetColor(stylesnav.white)
      document.body.classList.add(sidenav.sidenavOpen)

    }
    setNavOpen(!navIsOpen)
  }

  return (
    <div>
      <Nav color={color} position={"-80px"}>
        <div className={stylesnav.inner}>
          <h2>{props.title}</h2>
          {props.children}
          <div onClick={() =>toggleSidebar()}>
            {navIsOpen ? <FontAwesomeIcon icon={faTimes} size={"lg"}/> : <FontAwesomeIcon icon={faEllipsisH} size={"lg"}/>}
          </div>
        </div>
      </Nav>
      <AnimatedVisibility visible={navIsOpen}>
        <div id={sidenav.links}  className={sidenav.wrapper}>
          <a href={"/skills"}>skills</a>
          <a href={"/photography"}>photography</a>
          <a href={"/work"}>work</a>
          <a href={"/contact"}>contact</a>
        </div>
      </AnimatedVisibility>
    </div>
  )
}

export default NavWrapper